import React from "react";
import Aboutsection from "./Aboutsection";
import "./aboutsection.css"



const Aboutpage = () => {
    return (
      <div>
        <Aboutsection />
      </div>
  
  
    )
  }
  
  export default Aboutpage