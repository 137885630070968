import React from 'react'
import Servicessection from './Servicessection'
import "./services.css"

const Servicespage = () => {
  return (
    <div>

      <Servicessection />
      
    </div>
  )
}

export default Servicespage
