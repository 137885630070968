import React from 'react'
import Blogsection from './Blogsection'



const Blogpage = () => {
  return (
    <div>
      
      <Blogsection />
    </div>
  )
}

export default Blogpage
